import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Container,
  IconButton,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineFacebook, AiOutlineLinkedin } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#FDF6E6",
    color: "#fff",
    padding: "32px 0px",
  },
  lowerbox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  lowerbox1: {
    display: "flex",
    gap: "22px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  lowerbox2: {
    display: "flex",
    gap: "22px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  column: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      cursor: "pointer",
      fontSize: "16px",
    },
  },
  link: {
    display: "block",
    marginBottom: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.8)",
    textDecoration: "none",
    fontSize: "14px",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  logo: {
    height: 40,
    marginBottom: theme.spacing(2),
  },
  linksWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  iconButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  socialIcons: {
    display: "flex",
    gap: "15px", 
    marginTop:"14px",
  },
  socialIcon: {
    color: "rgba(0, 0, 0, 1)", // Set color to black
    fontSize: "24px", // Adjust the size if needed
    "&:hover": {
      color: theme.palette.primary.main, // Optionally add a hover color if desired
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  const [expandedSections, setExpandedSections] = useState({
    products: false,
    features: false,
    docs: false,
    company: false,
    contact: false,
  });

  const handleToggle = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
      <Box className={classes.footer}>
        <Container
          style={{ maxWidth: "100%", paddingLeft: "10%", paddingRight: "10%" }}
        >
          <Grid container justifyContent="space-between">
            <Grid item className={classes.column}>
              <Typography
                variant="h6"
                className={classes.heading}
                onClick={() => handleToggle("products")}
              >
                Products
                <IconButton className={classes.iconButton}>
                  {expandedSections.products ? (
                    <AiOutlineMinus />
                  ) : (
                    <AiOutlinePlus />
                  )}
                </IconButton>
              </Typography>
              <Box
                className={
                  expandedSections.products ? classes.show : classes.linksWrapper
                }
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <img src="/images/ios.svg" alt="iOS SDK" />
                  </Grid>
                  <Grid item>
                    <Link href="login" className={classes.link}>
                      iOS Chat SDK
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item>
                    <img src="/images/and.svg" alt="Android SDK" />
                  </Grid>
                  <Grid item>
                    <Link href="login" className={classes.link}>
                      Android Chat SDK
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item className={classes.column}>
              <Typography
                variant="h6"
                className={classes.heading}
                onClick={() => handleToggle("features")}
              >
                Features
                <IconButton className={classes.iconButton}>
                  {expandedSections.features ? (
                    <AiOutlineMinus />
                  ) : (
                    <AiOutlinePlus />
                  )}
                </IconButton>
              </Typography>
              <Box
                className={
                  expandedSections.features ? classes.show : classes.linksWrapper
                }
              >
                <Link href="/features" className={classes.link}>
                  One-on-One Text Chat
                </Link>
                <Link href="/features" className={classes.link}>
                  Media Sharing
                </Link>
                <Link href="/features" className={classes.link}>
                  Video and Audio Calls
                </Link>
                <Link href="/features" className={classes.link}>
                  Typing Indicators
                </Link>
                <Link href="/features" className={classes.link}>
                  Group Chats
                </Link>
              </Box>
            </Grid>

            <Grid item className={classes.column}>
              <Typography
                variant="h6"
                className={classes.heading}
                onClick={() => handleToggle("docs")}
              >
                Docs
                <IconButton className={classes.iconButton}>
                  {expandedSections.docs ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </IconButton>
              </Typography>
              <Box
                className={
                  expandedSections.docs ? classes.show : classes.linksWrapper
                }
              >
                <Link href="/signup" className={classes.link}>
                  Chat Messaging Docs
                </Link>
                <Link href="/signup" className={classes.link}>
                  Video & Audio Docs
                </Link>
                <Link href="/signup" className={classes.link}>
                  Quickstarts
                </Link>
                <Link href="/signup" className={classes.link}>
                  Tutorials
                </Link>
              </Box>
            </Grid>

            <Grid item className={classes.column}>
              <Typography
                variant="h6"
                className={classes.heading}
                onClick={() => handleToggle("company")}
              >
                Company
                <IconButton className={classes.iconButton}>
                  {expandedSections.company ? (
                    <AiOutlineMinus />
                  ) : (
                    <AiOutlinePlus />
                  )}
                </IconButton>
              </Typography>
              <Box
                className={
                  expandedSections.company ? classes.show : classes.linksWrapper
                }
              >
                <Link href="/aboutus" className={classes.link}>
                  About us
                </Link>
                <Link href="/whymzdk" className={classes.link}>
                  Why Mzadchat SDK
                </Link>
                <Link href="/t&c" className={classes.link}>
                  Terms & Conditions
                </Link>
                <Link href="/policy" className={classes.link}>
                  Privacy Policy
                </Link>
                <Link href="/security" className={classes.link}>
                  Security
                </Link>
              </Box>
            </Grid>

            <Grid item className={classes.column}>
              <Typography
                variant="h6"
                className={classes.heading}
                onClick={() => handleToggle("contact")}
              >
                Contact Us
                <IconButton className={classes.iconButton}>
                  {expandedSections.contact ? (
                    <AiOutlineMinus />
                  ) : (
                    <AiOutlinePlus />
                  )}
                </IconButton>
              </Typography>
              <Box
                className={
                  expandedSections.contact ? classes.show : classes.linksWrapper
                }
              >
                <Link href="/contactSales" className={classes.link}>
                  Sales
                </Link>
                <Link href="/helpCenter" className={classes.link}>
                  Support
                </Link>
                <Link href="/bugReport" className={classes.link}>
                  Bug Report
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />

          <Box className={classes.lowerbox}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <img src="/images/orangeLogo.svg" alt="MZADSDK Logo" />
              </Grid>
              <Grid item>
                <Box className={classes.center}>
                  <Typography variant="h6">MZADSDK All Rights Reserved</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.socialIcons}>
                  <Link href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <AiOutlineFacebook className={classes.socialIcon} />
                  </Link>
                  <Link href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                    <FaTwitter className={classes.socialIcon} />
                  </Link>
                  <Link href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                    <AiOutlineLinkedin className={classes.socialIcon} />
                  </Link>
                  <Link href="mailto:info@mzadsdk.com">
                    <MdEmail className={classes.socialIcon} />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
