// src/views/pages/stripe/Cancel.jsx

import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Cancel = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/'); // Redirect to home or the previous page
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Payment Canceled
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your payment was not completed. You can try again.
      </Typography>
      <Button variant="contained" color="secondary" onClick={handleGoBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default Cancel;
