// src/views/pages/stripe/Success.jsx

import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Success = () => {
  const history = useHistory();

  const handleContinue = () => {
    history.push('/'); // Redirect to home or dashboard after success
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase. Your payment has been processed successfully.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleContinue}>
        Continue
      </Button>
    </Box>
  );
};

export default Success;
