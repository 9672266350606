import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  makeStyles,
  Grid,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { styled } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, Link, useLocation } from "react-router-dom";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  menuItems: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "40px",
    alignItems: "center",
    position: "relative",
  },
  drawerList: {
    width: 250,
    height: "100%",
    background: "linear-gradient(45deg, black, transparent)",
  },
  buttonBox: {
    display: "flex",
    gap: 15,
    paddingBottom: "10px",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dropdownCompany: {
    position: "absolute",
    top: "105%",
    padding: "3% 4%",
    background: "#181721",
    boxShadow: theme.shadows[5],

    zIndex: "15 !important",
    display: "none",
    "& a": {
      padding: theme.spacing(2),
      display: "block",
      textDecoration: "none",
      color: "#E5E5E5",
    },

    "& .companyItems": {
      textDecoration: "none",
      paddingLeft: "0px",
      paddingBottom: "0px",
    },
    "& h2": {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      marginTop: "6px",
    },
    "& .rightList": {
      backgroundColor: "#181721",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "3%",
      paddingRight: "12%",
    },
    "& .leftList": {
      backgroundColor: "#14131D",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "10%",
      paddingRight: "9%",
    },
  },
  dropdownMenu: {
    position: "absolute",
    top: "105%",
    right: "-17%",
    width: "102vw",
    zIndex: "15 !important",
    boxShadow: theme.shadows[5],
    display: "none",
    "& .companyItems": {
      textDecoration: "none",
      paddingLeft: "0px",
      paddingBottom: "0px",
    },
    "& a": {
      padding: theme.spacing(2),
      display: "block",
      textDecoration: "none",
      color: "#E5E5E5",
    },
    "& h2": {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      marginTop: "6px",
      color: "#ffff",
    },
    "& body2": {
      color: "#ffff",
    },
    "& .rightList": {
      backgroundColor: "#181721",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "3%",
    },
    "& .leftList": {
      backgroundColor: "#14131D",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "10%",
      paddingRight: "9%",
    },
    "@media (min-width:1550px)": {
      right: "-16%",
    },
    "@media (max-width:1280px)": {
      right: "-3%",
    },
    "& .docsItem": {
      border: "1px solid #515151",
      padding: "15px",
      borderRadius: "10px",
      background: "#28272F",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },
  dropdownMenuVisible: {
    display: "block",
  },
}));

const NavBar = ({ bgColor }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDocsHovered, setIsDocsHovered] = useState(false);
  const [isProductsHovered, setIsProductsHovered] = useState(false);
  const [isCompanyHovered, setIsCompanyHovered] = useState(false);
  const isMobile = useMediaQuery("(max-width:1060px)");
  const history = useHistory();
  const location = useLocation();
  const [anchorNoti, setAnchorNoti] = useState(null);
  const openNoti = Boolean(anchorNoti);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNotiClose = () => {
    setAnchorNoti(null);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignUp = () => {
    history.push("/signup");
  };

  const handleLogIn = () => {
    history.push("/login");
  };
  const isActive = (path) => {
    const firstSegment = location.pathname.split("/")[1];
    return firstSegment === path;
  };
  const NavBarContainer = styled(AppBar)(({ isScrolled }) => ({
    background: "#FDF6E6",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
    height: "106px",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    boxShadow: isScrolled ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
    transition: 'box-shadow 0.3s ease-in-out',
    "@media (max-width:1280px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      marginTop: "23px",
    },
  }));

  const Logo = styled("img")({
    marginRight: "20px",
  });
  const handleNotiClick = (event) => {
    setAnchorNoti(event.currentTarget);
  };
  return (
    <div>
      <NavBarContainer position="static">
        <Toolbar>
          {isMobile && (
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                style={{ paddingBottom: "10px" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          <Logo
            src="/images/orangeLogo.svg"
            alt="Logo"
            onClick={() => history.push("/")}
            style={{
              paddingBottom: "10px",
              marginLeft: "-12px",
              cursor: "pointer",
            }}
          />
          {!isMobile && (
            <Box className={classes.menuItems} flexGrow={1}>
              {["Products", "Features", "Docs", "Company", "Pricing"].map(
                (text) => {
                  let path = "";

                  if (text == "Features") {
                    path = "/features";
                  }
                  if (text == "Pricing") {
                    path = "/pricing";
                  }

                  return (
                    <Box
                    key={text}
                    onMouseLeave={() => {
                      const delay = 500;
                      if (text === "Products") {
                        setTimeout(() => setIsProductsHovered(false), delay);
                      }
                      if (text === "Docs") {
                        setTimeout(() => setIsDocsHovered(false), delay);
                      }
                      if (text === "Company") {
                        setTimeout(() => setIsCompanyHovered(false), delay);
                      }
                    }}
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "10px",
                        color: "#403B52",
                      }}
                    >
                      <Link
                        to={path !== "" && path}
                        onMouseEnter={() => {
                          if (text === "Products") {
                            setIsCompanyHovered(false);
                            setIsDocsHovered(false);
                            setIsProductsHovered(true);
                          }
                          if (text === "Docs") {
                            setIsCompanyHovered(false);
                            setIsProductsHovered(false);
                            setIsDocsHovered(true);
                          }
                          if (text === "Company") {
                            setIsProductsHovered(false);
                            setIsDocsHovered(false);
                            setIsCompanyHovered(true);
                          }
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          variant="h6"
                          display="inline"
                          color="#403B52"
                          style={{
                            cursor: "pointer",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "18.78px",
                            color: isActive(text.toLowerCase())
                              ? "#F36D36"
                              : "#403B52",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.color = "#F36D36")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.color = "#403B52")
                          }
                        >
                          {text}
                        </Typography>
                      </Link>
                      {text === "Products" && (
                        <Box
                          className={`${classes.dropdownMenu} ${isProductsHovered ? classes.dropdownMenuVisible : ""
                            }`}
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid item>
                                      <img src="/images/message.svg" />
                                    </Grid>
                                    <Grid item style={{ marginLeft: "10px" }}>
                                      <Typography variant="h2">
                                        Chat & Messaging
                                      </Typography>
                                      <Typography style={{ color: "#ffff" }}>
                                        Flexible, Scalable, and feature-rich and{" "}
                                        <br />
                                        SDKs for your in-app messaging
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container>
                                    <Grid item>
                                      <Grid container direction="row">
                                        <Grid item>
                                          <img src="/images/featuresCall.svg" />
                                        </Grid>
                                        <Grid
                                          item
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <Typography variant="h2">
                                            Voice & Video Calls
                                          </Typography>
                                          <Typography
                                            style={{ color: "#ffff" }}
                                          >
                                            Integrate real-time video and audio{" "}
                                            <br /> engagement to your product
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Typography
                                variant="h2"
                                style={{ color: "#E5E5E580" }}
                              >
                                Features
                              </Typography>
                              <Grid container style={{ marginTop: "20px" }} spacing={2}>
                                <Grid item sm={6}>
                                  <Grid container style={{ padding: "10px", backgroundColor: "#3A3A3A", borderRadius: "8px", transition: "transform 0.3s" }} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"} onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                    <Grid item>
                                      <img src="/images/list.svg" alt="One-on-One Text Chat Icon" />
                                    </Grid>
                                    <Grid item>
                                      <Grid container direction="column" alignItems="flex-start" style={{ height: "100%" }}>
                                        <Typography variant="h5" style={{ color: "#ffff" }}> {/* Change to heading */}
                                          One-on-One Text Chat
                                        </Typography>
                                        <Typography style={{ color: "#ffff", marginTop: "5px" }}> {/* Add details below */}
                                          Seamlessly connect with users for real-time conversations.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item sm={6}>
                                  <Grid container style={{ padding: "10px", backgroundColor: "#3A3A3A", borderRadius: "8px", transition: "transform 0.3s" ,width:"89%"}} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"} onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                    <Grid item>
                                      <img src="/images/list.svg" alt="Media Sharing Icon" />
                                    </Grid>
                                    <Grid item>
                                      <Grid container direction="column" alignItems="flex-start" style={{ height: "100%" }}>
                                        <Typography variant="h5" style={{ color: "#ffff" }}>
                                          Media Sharing
                                        </Typography>
                                        <Typography style={{ color: "#ffff", marginTop: "5px" }}>
                                          Share images, videos, and files seamlessly with users.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item sm={6}>
                                  <Grid container style={{ padding: "10px", backgroundColor: "#3A3A3A", borderRadius: "8px", transition: "transform 0.3s" }} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"} onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                    <Grid item>
                                      <img src="/images/list.svg" alt="Video and Audio Calls Icon" />
                                    </Grid>
                                    <Grid item>
                                      <Grid container direction="column" alignItems="flex-start" style={{ height: "100%" }}>
                                        <Typography variant="h5" style={{ color: "#ffff" }}>
                                          Video and Audio Calls
                                        </Typography>
                                        <Typography style={{ color: "#ffff", marginTop: "5px" }}>
                                          Engage in real-time audio and video conversations.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item sm={6}>
                                  <Grid container style={{ padding: "10px", backgroundColor: "#3A3A3A", borderRadius: "8px", transition: "transform 0.3s",width:"89%" }} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"} onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                    <Grid item>
                                      <img src="/images/list.svg" alt="Typing Indicators Icon" />
                                    </Grid>
                                    <Grid item>
                                      <Grid container direction="column" alignItems="flex-start" style={{ height: "100%" }}>
                                        <Typography variant="h5" style={{ color: "#ffff" }}>
                                          Typing Indicators
                                        </Typography>
                                        <Typography style={{ color: "#ffff", marginTop: "5px" }}>
                                          See when users are typing in real-time.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item sm={6}>
                                  <Grid container style={{ padding: "10px", backgroundColor: "#3A3A3A", borderRadius: "8px", transition: "transform 0.3s" }} onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.02)"} onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"}>
                                    <Grid item>
                                      <img src="/images/list.svg" alt="Group Chats Icon" />
                                    </Grid>
                                    <Grid item>
                                      <Grid container direction="column" alignItems="flex-start" style={{ height: "100%" }}>
                                        <Typography variant="h5" style={{ color: "#ffff" }}>
                                          Group Chats
                                        </Typography>
                                        <Typography style={{ color: "#ffff", marginTop: "5px" }}>
                                          Create and manage group conversations effortlessly.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {text === "Docs" && (
                        <Box
                          className={`${classes.dropdownMenu} ${isDocsHovered ? classes.dropdownMenuVisible : ""
                            }`}
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "10px",
                                        width: "400px",
                                      }}
                                    >
                                      <Typography variant="h2">
                                        Supported Technology
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#ffff",
                                          marginTop: "20px",
                                        }}
                                      >
                                       Our platform is designed to integrate seamlessly with a variety of modern development technologies. Whether you’re building for mobile or web, we support the tools and frameworks that enable smooth and efficient app development. By providing flexible and powerful integration options, we ensure developers have the resources they need to create high-performance applications that scale effortlessly.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Grid
                                container
                                direction="column"
                                spacing={3}
                                style={{ width: "406px" }}
                              >
                                <Grid item>
                                  <Typography
                                    variant="h2"
                                    style={{
                                      color: "#E5E5E580",
                                      width: "306px",
                                    }}
                                  >
                                    Supported Technologies
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="space-between"
                                //spacing={4}
                                >
                                  <Grid item>
                                    <Box className="docsItem">
                                      <Typography
                                        style={{
                                          color: "#FFFFFFCC",
                                          textAlign: "center",
                                        }}
                                      >
                                        SWIFT
                                      </Typography>
                                      <img src="/images/swift.svg" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    {" "}
                                    <Box className="docsItem">
                                      <Typography
                                        style={{
                                          color: "#FFFFFFCC",
                                          textAlign: "center",
                                        }}
                                      >
                                        Kotlin
                                      </Typography>
                                      <img src="/images/kotlin.svg" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    {" "}
                                    <Box className="docsItem">
                                      <Typography
                                        style={{
                                          color: "#FFFFFFCC",
                                          textAlign: "center",
                                        }}
                                      >
                                        ReactJS
                                      </Typography>
                                      <img src="/images/kotlin.svg" />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {text === "Company" && (
                        <Box
                          className={`${classes.dropdownMenu} ${isCompanyHovered ? classes.dropdownMenuVisible : ""
                            }`}
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "10px",
                                        width: "400px",
                                      }}
                                    >
                                      <Typography variant="h2">
                                        Company
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#ffff",
                                          marginTop: "20px",

                                        }}
                                      >
                                        At MzadSDK, we are committed to providing cutting-edge solutions that power the communication infrastructure for modern applications. With a focus on innovation and customer success, we help businesses enhance user engagement and drive seamless experiences. Our robust platform supports millions of users worldwide, ensuring reliability, security, and performance for every app built with MzadSDK.
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Grid
                                container
                                direction="column"
                                style={{ marginLeft: "10%" }}
                              >
                                <Typography
                                  variant="h2"
                                  style={{ color: "#E5E5E580" }}
                                >
                                  Company
                                </Typography>

                                <Box>
                                  <Link
                                    to={"/aboutus"}
                                    style={{
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      About Us
                                    </Typography>
                                  </Link>
                                  <Link
                                    to={"/whymzdk"}
                                    className="companyItems"
                                  >
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Why Mzadchat SDK
                                    </Typography>
                                  </Link>
                                  <Link to={"/t&c"} className="companyItems">
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Terms & Conditions
                                    </Typography>
                                  </Link>
                                  <Link to={"/policy"} className="companyItems">
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Privacy Policy
                                    </Typography>
                                  </Link>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  );
                }
              )}
              <Box className={classes.buttonBox}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    border: "none",
                    borderRadius: "15px",
                    width: "135px",
                  }}
                  onClick={handleSignUp}
                >
                  Sign Up
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    border: "none",
                    borderRadius: "15px",
                    width: "135px",
                  }}
                  onClick={handleLogIn}
                >
                  Log In
                </Button>
              </Box>
            </Box>
          )}
        </Toolbar>
      </NavBarContainer>
      <Drawer
        anchor="left"
        open={isMobile ? drawerOpen : false}
        onClose={handleDrawerToggle}
      >
        <Box
          className={classes.drawerList}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <List>
            {["Features", "Pricing"].map((text) => (
              <ListItem
                button
                className={classes.drawerListItem}
                key={text}
                onClick={() => {
                  if (text === "Features") {
                    history.push("/features");
                  } else if (text === "Pricing") {
                    history.push("/pricing");
                  }
                }}
              >
                <ListItemText
                  primary={text}
                  classes={{ primary: classes.drawerListItemText }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleSignUp}
            >
              <ListItemText
                primary="Sign Up"
                classes={{ primary: classes.drawerListItemText }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleLogIn}
            >
              <ListItemText
                primary="Log In"
                classes={{ primary: classes.drawerListItemText }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

    </div>
  );
};

export default NavBar;
