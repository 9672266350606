// src/component/BuyPlanButton.jsx

import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51PckznRvesOQpLj7bOjkm2KI9Q8Ntc9K9k2c1lCvxBMTBGZm6GguBR7TC3PsExdWVdYZnJ3UL1OzlP6hNBc33M2k00VhzjnbEU');

const BuyPlanButton = () => {
  const history = useHistory();

  const handleClick = async () => {
    const stripe = await stripePromise;

    // Call your backend to create a Checkout Session
    const response = await fetch('/create-checkout-session', {
      method: 'POST',
    });
    const sessionId = await response.json();

    // Redirect to Checkout
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Buy Plan
    </Button>
  );
};

export default BuyPlanButton;
