import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import LoginLayout from "./layouts/LoginLayout";

import Homelayout from "./layouts/HomeLayout";
import MainLayout from "./layouts/MainLayout/MainLayout";

export const routes = [
  {
    exact: true,
    path: "/",

    component: lazy(() =>
      import("./views/pages/LandingPage/landingpage")
    ),
  },
  {
    exact: true,
    path: "/application",

    component: lazy(() => import("./views/pages/Auth/AllApplication")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/createapp",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/CreateApp")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/SignUP")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ForgetPassword")),
  },
  {
    exact: true,
    path: "/2FA",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/TwoFA")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/email-verification",
    layout: LoginLayout,
    component: lazy(() => import("./views/pages/Auth/Verification")),
  },

  {
    exact: true,
    path: "/t&c",
    layout: Homelayout,
    component: lazy(() =>
      import("./views/pages/Auth/staticContent/terms&conditions")
    ),
  },
  {
    exact: true,
    path: "/whymzdk",
    layout: Homelayout,
    component: lazy(() =>
      import("./views/pages/Auth/staticContent/WhyMazadchatSDK")
    ),
  },
  {
    exact: true,
    path: "/security",
    layout: Homelayout,
    component: lazy(() =>
      import("./views/pages/Auth/staticContent/CompanyNameSecurity")
    ),
  },
  {
    exact: true,
    path: "/policy",
    layout: Homelayout,
    component: lazy(() =>
      import("./views/pages/Auth/staticContent/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/homelayout",
    component: lazy(() => import("./layouts/HomeLayout")),
  },
  {
    exact: true,
    path: "/features",
    component: lazy(() => import("./views/pages/BeforeLogin/Features")),
  },
  {
    exact: true,
    path: "/credentials",
    layout: MainLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Credentials/Credentials")
    ),
  },
  {
    exact: true,
    path: "/users",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/User/ActiveUser")),
  },
  {
    exact: true,
    path: "/users/inactive",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/User/InactiveUser")),
  },

  {
    exact: true,
    path: "/apis",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/API/apis")),
  },
  {
    exact: true,
    path: "/sdks",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Sdks/sdk")),
  },
  {
    exact: true,
    path: "/sdkdocs",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Sdks/sdkDocumentation")),
  },
  {
    exact: true,
    path: "/users/viewUser",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/User/ViewUser")),
  },
  {
    exact: true,
    path: "/groups",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Groups/ActiveGroup")),
  },
  {
    exact: true,
    path: "/groups/inactive",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Groups/InActiveGroup")),
  },
  {
    exact: true,
    path: "/groups/viewMember",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Groups/ViewMembers")),
  },
  {
    exact: true,
    path: "/overview",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/OverView/OverView")),
  },
  {
    exact: true,
    path: "/plans&billings",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Plans&Billings/plans&billings")),
  },
  {
    exact: true,
    path: "/roles",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Roles/Roles")),
  },
  {
    exact: true,
    path: "/chats",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Chats/Chats")),
  },
  {
    exact: true,
    path: "/widgets",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/Widgets")),
  },
  {
    exact: true,
    path: "/widgets/view",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/ViewWidgets")),
  },
  {
    exact: true,
    path: "/insightssss",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Insights/insights")),
  },
  {
    exact: true,
    path: "/widgets/view/install",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/Install")),
  },
  {
    exact: true,
    path: "/calls",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Calls/Calls")),
  },
  {
    exact: true,
    path: "/widgets/view/customize",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/Customize")),
  },
  {
    exact: true,
    path: "/widgets/view/settings",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/Settings")),
  },
  {
    exact: true,
    path: "/widgets/view/settings/chatComponent",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Widgets/ChatsComponent")),
  },
  {
    exact: true,
    path: "/profile",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/team",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Team/Team")),
  },
  {
    exact: true,
    path: "/general",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Settings/SettingsGeneral")),
  },
  {
    exact: true,
    path: "/general/appsettings",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Settings/AppSettings")),
  },
  {
    exact: true,
    path: "/general/chatConfiguration",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Settings/ChatConfiguration")),
  },
  {
    exact: true,
    path: "/pricing",
    
    component: lazy(() => import("./views/pages/BeforeLogin/Pricing")),
  },
  {
    exact: true,
    path: "/aboutus",
    
    component: lazy(() => import("./views/pages/BeforeLogin/AboutUs")),
  },
  {
    exact: true,
    path: "/plans&billings/changePlan",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Plans&Billings/ChangePlan")),
  },
  {
    exact: true,
    path: "/bugReport",
    component: lazy(() => import("./views/pages/Contact/BugReport")),
  },
  {
    exact: true,
    path: "/helpCenter",
    component: lazy(() => import("./views/pages/Contact/HelpCenter")),
  },
  {
    exact: true,
    path: "/contactSales",
    component: lazy(() => import("./views/pages/Contact/ContactSales")),
  },
  {
    exact: true,
    path: "/insights",
    layout: MainLayout,
    component: lazy(() => import("./views/pages/Dashboard/Insights/Users&Groups")),
  },
  // {
  //   exact: true,
  //   path: "/navbar",
  //   //layout: MainLayout,
  //   component: lazy(() => import("./views/pages/Dashboard/Insights/Messaging")),
  // },
];
