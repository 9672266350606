import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  Dashboard,
  Category,
  AccountCircle,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  FitnessCenter,
  Book,
  LocalDining,
  Fastfood,
  Restaurant,
  SportsHandball,
  EventNote,
  Subscriptions,
  TrackChanges,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
//import Overview from "../../../public/images/overview.svg"

//import profileImg from "../assets/profileimg-h2m.png";

const useStyles = makeStyles((theme) => ({
  sidebarAdminName: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: "20px",
    },
  },
  sidebar: {
    display: "flex",
    //flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  activeSidbarList: {
    cursor: "pointer",

    "& .MuiTypography-root": {
      color: "rgb(255, 254, 255)",
      fontWeight: 500,
      fontSize: "16px",
    },
    "& .MuiSvgIcon-root": {
      color: "rgb(0, 156, 203) !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "0px !important",
      display: "flex",
      //flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  gradientText: {
    fontWeight: 500,
    fontSize: "16px",
    background: "#FFFFFF",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  categoryStyle: {
    color: "#667085",
    marginTop: "5px",
    marginBottom: "5px",
    fontSize: "12px",
    paddingLeft: "40px",
    paddingRight: "20px",
  },
  sidebarItem: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: "40px",
    paddingRight: "20px",

    "& .MuiSvgIcon-root": {
      color: "rgb(225, 140, 168) !important",
    },

    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: "14px",
      color: "rgba(64, 59, 82, 1)",
      fontFamily:"'Raleway'"
    },
    "& .MuiListItemIcon-root": {
      minWidth: "0px !important",
      marginRight: "10px",
    },
  },
  ActiveSidebarItem: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F36D36",
    paddingLeft: "40px",
    paddingRight: "20px",

    "& .MuiSvgIcon-root": {
      color: "rgb(225, 140, 168) !important",
    },

    "& .MuiTypography-root": {
      fontWeight: 500,
      fontSize: "16px",
      color: "#667085",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "0px !important",
      marginRight: "10px",
    },
  },
  sidebarprofieInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //alignItems: "center",
    paddingLeft:"21px",
    border: " 1px dashed #F36D36",
    width: "216px",
    height: "71px",
    borderRadius: "10px",

    "& .h4": {
      fontWeight: 500,
      fontSize: "14px",
    },
  },
  sidebarprofieImg: {
    //borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
}));

const Sidebar = ({ onLogoutClick }) => {
  const classes = useStyles();
 
  const location = useLocation();
  const history = useHistory();

 
  

  const isActive = (path) => {
    const firstSegment = location.pathname.split("/")[1];
    return firstSegment === path;
  };
  const { appName, appId } = location.state || { appName: '', appId: '' };
  const storedAppName = sessionStorage.getItem('appName');
  const storedAppId = sessionStorage.getItem('appId');
const storedlastAppUsed = sessionStorage.getItem('lastUsedApp');

  return (
    <Box
      style={{
        padding: "0px 0px 20px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List>
      <ListItem style={{ paddingLeft: "20px", paddingRight: "20px", marginBottom: "10px" }}>
  <Box className={classes.sidebarprofieInfo}>
    <Typography
      style={{ color: "#0C111D", fontWeight: 500, fontSize: "14px" }}
    >
       {`${(storedAppName || "My First App").slice(0,30)}`} {/* Fallback name */}
    </Typography>
    <Typography
      style={{ fontFamily: "Inter", color: "#667085", fontWeight: 400, fontSize: "12px" }}
    >
      App ID: {storedAppId || storedlastAppUsed || "2434535dff5cdc6f7"} {/* Fallback ID */}
    </Typography>
  </Box>
</ListItem>

        <ListItem
          className={
            isActive("overview")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/overview")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img
              src={
                isActive("overview")
                  ? "/images/overviewA.svg"
                  : "/images/overviewIA.svg"
              }
              alt="Overview Icon"
            />
          </ListItemIcon>
          <ListItemText
            primary="Overview"
            className={isActive("overview") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
         className={
          isActive("credentials")
            ? classes.ActiveSidebarItem
            : classes.sidebarItem
        }
          onClick={() => history.push("/credentials")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("credentials")? "/images/cridentailsA.svg":"/images/Credentials.svg"} alt="Credentials Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Credentials"
            className={isActive("credentials") ? classes.gradientText : null}
          />
        </ListItem>
        <Typography variant="body2" className={classes.categoryStyle}>
          Manage
        </Typography>
        <ListItem
          className={
            isActive("users")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/users")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img
              src={
                isActive("users") ? "/images/usersA.svg" : "/images/Users.svg"
              }
              alt="Users Icon"
            />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            className={isActive("users") ? classes.gradientText : null}
          />
        </ListItem>

        <ListItem
          className={
            isActive("groups")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/groups")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("groups")? "/images/groupsA.svg":"/images/Groups.svg"} alt="Groups Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Groups"
            className={isActive("groups") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
          className={
            isActive("roles")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/roles")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("roles")? "/images/rolesA.svg":"/images/Roles.svg"} alt="Roles Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Roles"
            className={isActive("roles") ? classes.gradientText : null}
          />
        </ListItem>
        <Typography variant="body2" className={classes.categoryStyle}>
          Moderate
        </Typography>
        <ListItem
          className={
            isActive("chats")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/chats")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("chats")?  "/images/chatsA.svg":"/images/Chats.svg"} alt="Chats Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Chats"
            className={isActive("chats") ? classes.gradientText : null}
          />
        </ListItem>

        <ListItem
          className={
            isActive("calls")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/calls")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("calls")?  "/images/callsA.svg":"/images/calls.svg"} alt="Calls Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Calls"
            className={isActive("calls") ? classes.gradientText : null}
          />
        </ListItem>
        <Typography variant="body2" className={classes.categoryStyle}>
          Integration
        </Typography>
        <ListItem
          className={
            isActive("widgets")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/widgets")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("widgets")? "/images/widgetsA.svg": "/images/Widgets.svg"} alt="Widgets Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Widgets"
            className={isActive("widgets") ? classes.gradientText : null}
          />
        </ListItem>

        <ListItem
          className={
            isActive("sdks")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/sdks")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("sdks")? "/images/sdkA.svg": "/images/SDKs.svg"} alt="SDKs Icon" />
          </ListItemIcon>
          <ListItemText
            primary="SDKs"
            className={isActive("sdks") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
         className={
          isActive("apis")
            ? classes.ActiveSidebarItem
            : classes.sidebarItem
        }
          onClick={() => history.push("/apis")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("apis")? "/images/apiA.svg": "/images/APIs.svg"} alt="APIs Icon" />
          </ListItemIcon>
          <ListItemText
            primary="APIs"
            className={isActive("apis") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
          className={
            isActive("insights")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/insights")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("insights")? "/images/InsightsA.svg": "/images/Insights.svg"} alt="Insights Icon" />
          </ListItemIcon>
          <ListItemText
            primary="Insights"
            className={isActive("insights") ? classes.gradientText : null}
          />
        </ListItem>
        <Typography variant="body2" className={classes.categoryStyle}>
          Settings
        </Typography>
        <ListItem
          className={
            isActive("general")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/general")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("general")? "/images/GeneralA.svg": "/images/General.svg"} alt="General Icon" />
          </ListItemIcon>
          <ListItemText
            primary="General"
            className={isActive("general") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
          className={
            isActive("team")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/team")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
            <img src={isActive("team")? "/images/groupsA.svg":"/images/Groups.svg"} alt="Team" />
          </ListItemIcon>
          <ListItemText
            primary="Team"
            className={isActive("team") ? classes.gradientText : null}
          />
        </ListItem>
        <ListItem
          className={
            isActive("plans&billings")
              ? classes.ActiveSidebarItem
              : classes.sidebarItem
          }
          onClick={() => history.push("/plans&billings")}
        >
          <ListItemIcon className={classes.sidebarIcon}>
          {/* <img src="/images/Plans&Billings.svg" alt="Plans & Billings" /> */}
          <img src={isActive("plans&billings")? "/images/Plans&BillingsA.svg":"/images/Plans&Billings.svg"} alt="Team" />
          </ListItemIcon>
          <ListItemText
            primary="Plans & Billings"
            className={isActive("plans&billings") ? classes.gradientText : null}
          />
        </ListItem>

        <ListItem onClick={onLogoutClick} className={classes.sidebarItem}>
          <ListItemIcon className={classes.sidebarIcon}>
            <img src="/images/Logout.svg" alt="Logout Icon" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;
